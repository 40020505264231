import React, { useCallback, useMemo } from "react";
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom'
import Header from "@pages/workList/Header";
import {menuList, menuListType} from "@layout/menu";
import classNames from "classnames";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";

export default function Layout() {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const memoizedMenuList = useMemo(() => menuList, []);
    const handleMenuNavigation = useCallback((item:menuListType) => {
          return (e:React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            navigate(item.url); // 페이지 이동
          };
        },
        [navigate]
      );

    return (
        <div className="layout_wrap">
            <Header/>
            <div className="side_menu_wrap">
                <ul id="menu_list">
                    {memoizedMenuList.map(item => {
                        const childrens = item?.children?.map(val => val.url) || []
                        return(
                        <li key={item.id}>
                            <Link
                                to={item.url}
                                onClick={handleMenuNavigation(item)}
                                className={classNames({active: pathname.includes(item.id)})}>
                                <item.icon/>
                                <div className="menu_item_title">{item.name}</div>
                                {item.children && (
                                    <div className="menu_item_arrow">
                                        { pathname.includes(item.id) ? <IoIosArrowUp/> :<IoIosArrowDown/> }
                                    </div>
                                )}
                            </Link>
                            {item.children && (
                                <div className={classNames('sub_menu_wrap', {active: item.url === pathname || childrens.includes(pathname)})}>
                                    <ul className="menu_sub_list">
                                        {item.children.map(child => {
                                            return (
                                                <li key={child.id}>
                                                    <Link
                                                        to={child.url}
                                                        onClick={handleMenuNavigation(child)}
                                                        className={classNames({active: child.url === pathname})}>
                                                        <child.icon/>
                                                        <div className="menu_item_title">{child.name}</div>
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                        </li>
                    )})}
                </ul>
            </div>
            <div className="contents_wrap">
                <Outlet/>
            </div>
        </div>

    )
}
