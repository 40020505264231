import {useMutation} from "@tanstack/react-query"
import {useNavigate} from "react-router-dom";
import UserService from "@service/api/UserService";
import {CustomConfirm} from "@lib/CustomConfirm";
import {removeHeaderToken, setHeaderToken} from "@lib/axios";
import {encryptStorage} from "@lib/encryptStorage";
import {useUserActions} from "@store/user/store";
import {Toast} from "@lib/Toas";
export interface ErrorInterface {
    code: string
    detail: string
    msg: string
    status: number
}
export const useUserLogin = () => {
    const navigate = useNavigate();
    const userActions = useUserActions();
    const mutationResults = useMutation({
        mutationFn: UserService.login,
        onSuccess: (data, variables) => {
            // 로그인 성공
            if(data.status === 200) {
                setHeaderToken(data.token);
                encryptStorage.setItem('token', data.token);
                encryptStorage.setItem('refreshToken', data.refresh_token);
                userActions.setUserData(data.user_data);
                userActions.setToken(data.token);
                userActions.setRefreshToken(data.refresh_token);
                Toast('로그인 되었습니다.', 'success');
                navigate('/');
            }
            // 중복로그인
            else if(data.status === 202) {
                CustomConfirm('중복로그인', '기존에 사용중인 아이디입니다.<br/>로그아웃 후 다시 로그인 하시겠습니까?', 'warning', (result) => {
                    if (result.isConfirmed) {
                        mutationResults.mutate({ ...variables, user_overwrite: true });
                    }
                });
            }
            // 기타 오류
            else{
                Toast('알수 없는 오류가 발생했습니다.', 'warning');
            }
        },
        onError: (error:ErrorInterface) => {
            switch (error.code) {
                case '4000009':
                default:
                    Toast('아이디 또는 비밀번호가 잘못되었습니다.', 'error');
                    break;
            }
        },
    })
    return mutationResults;
}

export const useUserLogout = () => {
    const navigate = useNavigate();
    const userActions = useUserActions();
    return useMutation({
        mutationFn: UserService.logout,
        onSuccess: (data) => {
            if(data.status === 200) {
                encryptStorage.removeItem('token');
                encryptStorage.removeItem('refreshToken');
                navigate('/login');
                Toast('로그아웃 되었습니다.', 'success');
                setTimeout(()=>{
                    removeHeaderToken();
                    userActions.setUserData(null);
                    userActions.setToken('');
                    userActions.setRefreshToken('');
                }, 150)


            }
            // 기타 오류
            else{
                Toast('알수 없는 오류가 발생했습니다.', 'warning');
            }
        },
        onError: (error:ErrorInterface) => {
            switch (error.code) {
                case '4000009':
                default:
                    Toast('아이디 또는 비밀번호가 잘못되었습니다.', 'error');
                    break;
            }
        },
    })
}
