import React, {useEffect, useState} from "react";
import {useUserLogin} from "@query/UserQuery";
import {LoginFrom} from "@UserTypes/UserTypes";
import { useForm} from "react-hook-form";
import "@assets/css/user/login.css"
import {Toast} from "@lib/Toas";
import {useNavigate} from "react-router-dom";
import {encryptStorage} from "@lib/encryptStorage";

export default function Login() {
    const {mutate: userLogin} = useUserLogin()
    const navigate = useNavigate();
    const token = encryptStorage.getItem('token')
    const {register, handleSubmit, setValue} = useForm<LoginFrom>(
        {
            defaultValues: {user_id: '', user_passwd: '', user_overwrite: false},
            resetOptions: {
                keepErrors: false,
            }
        })
    const [remember, setRemember] = useState(false);
    const onSubmit = handleSubmit((data)=>{
        if (remember) {
            const rememberedUser = {
                userId: data.user_id,
                userPasswd: data.user_passwd,
                timestamp: new Date().getTime()
            };
            encryptStorage.setItem('rememberedUser', JSON.stringify(rememberedUser));
        } else {
            encryptStorage.removeItem('rememberedUser');
        }
        userLogin(data)
    }, (errors)=>{
        for (const item of Object.values(errors)) {
            if (item) {
                Toast(item.message, 'warning');
                (item?.ref as HTMLInputElement)?.focus();
                break;
            }
        }
    })
    const changeRemember = (e:React.ChangeEvent<HTMLInputElement>)=>{
        setRemember(e.target.checked);
        if(!e.target.checked){
            encryptStorage.removeItem('rememberedUser');
        }
    }
    useEffect(() => {
        const EXPIRATION_DAYS = 30;
        const savedData = encryptStorage.getItem('rememberedUser');
        if (savedData) {
            const { userId,userPasswd, timestamp } = savedData;
            const now = new Date().getTime();
            if (now - timestamp < EXPIRATION_DAYS * 24 * 60 * 60 * 1000) {
                setValue('user_id', userId)
                setValue('user_passwd', userPasswd)
                setRemember(true);
            } else {
                // 만료된 데이터 삭제
                encryptStorage.removeItem('rememberedUser');
            }
        }
    }, []);
    useEffect(() => {
        if(token) navigate('/');
    }, [token]);
    return (
        <div className="Login_wrap">
            <h1>manager LOGIN</h1>
            <form className="login_box" onSubmit={onSubmit}>
                <div className="user_box">
                    <input placeholder="ID" type="text" {...register('user_id', {
                        required: "ID는 필수 입력 입니다.",
                        pattern: {
                            value: /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]*$/,
                            message: "영문, 숫자, 특수문자만 입력 가능합니다."
                        }
                    })}/>
                </div>
                <div className="user_box">
                    <input placeholder="Password" type="password" {...register('user_passwd',{
                        required: "비밀번호는 필수 입력입니다.",
                        minLength: {
                            value: 4,
                            message: "비밀번호는 최소 4자리 입니다."
                        },
                        pattern: {
                            value: /^[A-Za-z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]*$/,
                            message: "영문, 숫자, 특수문자만 입력 가능합니다."
                        }
                    })}/>
                </div>
                <input type="checkbox" id="rmb" name="rmb"  onChange={changeRemember} checked={remember}/>
                <label htmlFor="rmb">Remember</label>
                {/*<a href="" className="fd_pw">
                    Forgot Password?
                </a>*/}
                <button type="submit" className="btn_login">Login</button>
            </form>
        </div>
    )
}
